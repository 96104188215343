import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import CreatableSelectField from './elements/creatableSelect'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import './assets/styles/_index.scss'
import { postFormData } from "./api/Api"
import { phoneNumberValidation, uniqueID, Mail_Function } from "../common/utils"
import { PropertyBaseAPI } from "../../queries/api_links"
import { useLocation } from "@reach/router";
import axios from "axios"
import * as qs from "query-string"

function BookYourValuationForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);


  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");
  const [phoneNum, setPhoneNum] = useState("+971 5086 7771");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const location = useLocation();

  let isdubai = location.href?.indexOf('dubai')

  const handlechange = event => {
    // remove initial empty spaces
    console.log("select_event",event);
    if (event.type === "select" || event.__isNew__) {
      event.value = event.value.trimStart()
    } else {
      if (event.target.name === "telephone") {
        var valid_phone = phoneNumberValidation(event.target.value);
        if (valid_phone) {
          event.target.value = valid_phone;
        } else {
          event.target.value = '';
        }
      } else {
        event.target.value = event.target.value.trimStart()
      }
    }

  }

  function updatePhone() {
    if (parseInt(isdubai) > 0) {
      setPhoneNum("+97144397247");
    }
  }
  useEffect(() => {

    // updatePhone();

    //console.log("token", token);
    if (token !== '') {

      const processFromData = async () => {

        let formData = new FormData();

        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => {

          window.grecaptcha.reset();
          await Mail_Function(formvalues);


        });

        formvalues['g-recaptcha-response'] = token;


        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          //'formType': 'form-'+fields[0].event_tracking,
          'formType': formvalues.interested_in,
          'formId': 'form-' + fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': props.formLabel
        });

        setShowerror(false);
        setThankyou(true);
        setTimeout(() => {
          setThankyou(false)
        }, 3000); 
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);



  const fields = ([
    {
      element: "config",
      formname: "Book Your Valuation",
      form_type: "Book Your Valuation",
      error_text: "Highlighted fields are required | invalid",
      success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
      email_temp_user: "book_your_valuation_user",
      email_temp_admin: "book_your_valuation_admin",
      email_server_func: "contact",
      event_tracking: "contact",
      page_url: "/contact",
      email_subject_user: "Book Your Valuation",
      email_subject_admin: "Book Your Valuation"
    },
    {
      grpmd: "12",
      label: "Full Name",
      placeholder: "",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[a-zA-Z0-9]+(\\s+[a-zA-Z0-9]+)*$",
      labelClass: "annual-txt"
    },
    {
      grpmd: "12",
      label: "Email",
      placeholder: "",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "annual-txt"

    },
    {
      grpmd: "12",
      label: "Phone",
      placeholder: "",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "annual-txt"

    },
    {
      grpmd: "12",
      label: "Would you like to Sell or Rent?",
      placeholder: "",
      name: "interested_in",
      element: "select",
      required: true,
      values: ['Sell', 'Rent'],
      labelClass: "annual-txt",
      class: "full_wdth_box"
    },
    {
      grpmd: "12",
      label: "Property Type",
      placeholder: "",
      name: "property_type",
      element: "select",
      required: true,
      values: ['Apartments', 'Townhouses', 'Villas', 'Penthouses', 'Duplexes', 'Lands', 'Office Spaces', 'Chalets'],
      labelClass: "annual-txt",
      class: "full_wdth_box"
    },
    {
      grpmd: "12",
      label: "Location",
      placeholder: "Select Location",
      name: "location",
      values: props.areaOptions,
      type: "text",
      element: "creatable_select",
      class: "mb-4",
      required: true,
      labelClass: "annual-txt"
    },
    {
      grpmd: "6",
      name: "SUBMIT",
      type: "submit",
      element: "button",
      value: "Submit",
      class: "btn-primary col-md-12",
      labelClass: "",
      customClass: "btn-wrapper col-md-12"
    },
    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef
    },
  ]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      
      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;

      if (json.enquirytype == "Interior Decoration" || json.enquirytype == "Construction Services") {
        json['to_email_id'] = "decor@thepearlgates.com";
      }

      if (props.url) {
        json['contact_url'] = props.url
      }

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();

    }
  };


  return (
    <div className="form">

      {showerror && <div className="alert alert-danger">
        {fields[0].error_text}
      </div>}

      {showthankyou && <div className="alert alert-success">
        {fields[0].success_text}
      </div>}
      <div ref={myRef}></div>

      <Form name={fields[0].formname} className={props.classNames} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>

        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="form_label" value={props.formLabel} />
        <input type="hidden" name="to_email_id" value={props.to_email_id} /> 
        <input type="hidden" name="bot-field" /> 
        <Form.Row>
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  selectedValue={props.typeVal}
                />
              );
            }
            if ("creatable_select" === field.element) {
              return (
                <CreatableSelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  selectedValue={props.typeVal}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <>
                 
                  <TextAreaField
                    name={field.name}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    labelClass={field.labelClass}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                  />
                </>
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <>

                  <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    type={field.type}
                    value={field.value}
                    key={`${field.element}~${index}`}
                    grpmd={field.grpmd}
                    customClass={field.customClass}
                  />

                </>
              );
            }
          })
          }
        </Form.Row>

      </Form>
    </div>
  );
}


const BookYourValuationFormPage = (props) => (
  <BookYourValuationForm {...props} />

)

export default BookYourValuationFormPage