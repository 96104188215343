import axios from 'axios';

var YoutubeAPIKey = "AIzaSyBqXXYIbth4_0f6544KjOTLFEnuX80lb98";
var PropertybaseAPILink = "https://front-desk.propertybase.com/forms/8d15195f2f7b771c290607e66c8a6c8caa0a8ec0";

export const GetVideos = async(playlist_id, pageToken) => {
    var pageToken_1 = pageToken ? pageToken : '';
    var youtube_video_lnk = "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=1000&playlistId="+playlist_id+"&key="+YoutubeAPIKey+"&pageToken="+pageToken_1;
    
    const res = await axios.get(youtube_video_lnk);
    return res;
}

export const GetAllVideos = async(playlist_id, pageToken) => {
    var pageToken_1 = pageToken ? pageToken : '';
    var youtube_video_lnk = "https://www.googleapis.com/youtube/v3/search?part=snippet,id&maxResults=1000&key="+YoutubeAPIKey+"&channelId=UCKw9dpWby5DTkAzXEANx_qw&pageToken="+pageToken_1;
    
    const res = await axios.get(youtube_video_lnk);
    return res;
}

export const PropertyBaseAPI = async(form_data) => {
    var api_link = PropertybaseAPILink+"?"+form_data;
    
    const res = await axios.post(api_link);
    return res;
}