import React, { useState, useEffect, useRef } from "react"
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import axios from "axios"
import {
  Container,
  Row,
  Col,
} from "react-bootstrap"
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import secLogoBg1 from "../../images/form-combined/sec-logo-bg1.svg"
import BookYourValuationForm from "../../components/forms/book-your-valuation-form";
 
const BookYourValuation = (props) => {

  const [areaOptions, setAreaOptions] = useState([]);
  let prev_path_name = props.location.state && props.location.state.prevPath && (props.location.state.prevPath).split('/');
  let prev_url = prev_path_name && prev_path_name[prev_path_name.length - 1];

  if (prev_url === "") {
    prev_url = prev_path_name[prev_path_name.length - 2];
  }


  let query_params = queryString.parse(props.location.search);

  const getAreas = async () => { 
    try {
        const { data } = await axios.get(`${process.env.GATSBY_STRAPI_SRC}/json/areas.json`, {
            headers: {
            Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
            }})
    
        let options = []
        if(data && data.length > 0){
          data.map((op)=>{
            options.push({ label: op.name, value: op.slug, type:"select"})
          })
        
        }
        if(areaOptions.length == 0){
          setAreaOptions(options) 
        }
       
        // return options;
    } catch (error) {
        // console.error(error)
    }
  }

  useEffect(() => {
    getAreas();
  });


  var form_heading = '';
  var meta_desc = "Get an accurate property valuation in Dubai with our expert services. Discover how much your house or property is worth with our free online valuation tool";


  return (
    <Layout Layout={'Without_Banner'} classNames="Without_Banner">
      <SEO title={`Book your Valuation | Property Valuation in Dubai`} description={`${meta_desc}`} />
      <div className="page-content pre_qualification book-your-valuation">
        <div className="questions-list text-center">
          <img src={secLogoBg1} className="sec-logobg" />
          <Container>
            <Row>
              <Col md={12} lg={{ span: 6, offset: 3 }}>
                <div className="form-layout">
                  <h2>Book your valuation</h2>
                  {/* <p className="pcontent">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu tellus nec leo cursus euismod. Nunc quis felis felis donec ultricies ante tortor sed.</p> */}
                  <BookYourValuationForm
                    // typeVal={typeVal} 
                    // to_email_id={contact_office.Email} 
                    // url={location.href} 
                    // alias={props.GQLPage.Alias}
                    areaOptions={areaOptions}
                    classNames="application-form text-left" />
                </div> 
              </Col>
            </Row>
          </Container>
        </div> 
      </div> 
    </Layout>
  )
}

export default BookYourValuation